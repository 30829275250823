<template>
  <div class="upload2">
    <div class="flex">
      <div v-if="dialogImageUrl" class="uploadImg flex">
        <el-image :src="dialogImageUrl">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <div v-if="isOperation" class="icon fcc">
          <span @click="isDialogVisible" class="zoom">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span @click="handleRemove" class="delete">
            <i class="el-icon-delete"></i>
          </span>
        </div>
        <div v-if="authProgress != 0" class="progresss">
          <div class="progress_bj"></div>
          <el-progress type="circle" :percentage="authProgress"></el-progress>
        </div>
      </div>

      <template v-if="fileList.length">
        <div class="uploadImg flex" v-for="(item, index) in fileList" :key="index">
          <el-image :src="item.url">
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image>
          <div class="icon fcc">
            <span @click="isDialogVisible(item.url)" class="zoom">
              <i class="el-icon-zoom-in"></i>
            </span>
            <span @click="handleRemoveAll(index, fileList)" class="delete">
              <i class="el-icon-delete"></i>
            </span>
          </div>
        </div>
      </template>
      <el-upload v-if="isOperation" action="#" list-type="picture-card" :auto-upload="false" :show-file-list="false" :on-preview="handlePictureCardPreview" :on-change="handleChange" :on-remove="handleRemove" accept=".png, .jpg" :multiple="multiple" :limit="limit" :on-exceed="handleExceed" :file-list="fileList" :disabled="!uploadDisabled">
        <div class="plus_tips">
          <div class="icon_plus fac jc">
            <span>+</span>
          </div>
          <span>本地上传</span>
        </div>
      </el-upload>
    </div>
    <el-dialog :visible.sync="dialogVisible" class="imgDialog" top="15px">
      <img width="100%" :src="limit > 1 ? dialogListImageUrl : dialogImageUrl" />
    </el-dialog>
  </div>
</template>

<script>
import { saveCoverByWeb } from '@/utils/serveApi';
export default {
  name: 'upload2',
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 1
    },
    filesList: Array,
    dialogImage: String,
    imageSub: String,
    queryId: {
      type: String,
      default: ''
    },
    isOperation: {
      type: Boolean,
      default: true
    },
    highligh: {
      type: String,
      default: ''
    },
    videoId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      disabled: false,
      dialogImageUrl: '',
      dialogListImageUrl: '',
      dialogVisible: false,
      fileList: [],
      uploader: null,
      authProgress: 0,
      uploadDisabled: true,
      statusText: ''
    };
  },
  methods: {
    createUploader(type) {
      const self = this;
      const uploader = new AliyunUpload.Vod({
        timeout: 60000,
        partSize: 1048576,
        parallel: 5,
        retryCount: 3,
        retryDuration: 2,
        region: '',
        userId: this.$store.state.userData.userId,
        addFileSuccess: () => {
          self.uploadDisabled = false;
          self.statusText = '添加文件成功, 等待上传...';
        },
        onUploadstarted: async uploadInfo => {
          const getVoucherData = self.$store.state.getVoucherData;
          const videoId = getVoucherData ? getVoucherData.videoId : this.videoId;
          //0表示是完整视频的封面,1高光视频或小技能视频的封面
          const type = this.queryId == 1 || this.highligh ? 1 : 0;
          const { data } = await saveCoverByWeb({ videoId, type });
          const { imgUploadAddress, imgUploadAuth } = data;
          uploader.setUploadAuthAndAddress(uploadInfo, imgUploadAuth, imgUploadAddress, videoId);
          self.statusText = '文件开始上传...';
        },
        onUploadSucceed: uploadInfo => {
          self.statusText = '文件上传成功!';
          self.authProgress = 0;
          this.$emit('dialogImage', '', 1);
        },
        onUploadFailed: () => (self.statusText = '文件上传失败!'),
        onUploadCanceled: () => (self.statusText = '文件已暂停上传'),
        onUploadProgress: (uploadInfo, totalSize, progress) => {
          self.authProgress = Math.ceil(progress * 100);
          self.statusText = '文件上传中...';
        },
        onUploadEnd: () => (self.statusText = '文件上传完毕')
      });
      return uploader;
    },
    uploadAnLiYunImg(files) {
      if (!files) {
        this.$message('请先选择需要上传的文件!');
        return;
      }
      if (this.uploader) {
        this.uploader.stopUpload();
        this.authProgress = 0;
        this.statusText = '';
      }
      this.uploader = this.createUploader();
      this.uploader.addFile(files, null, null, null, '{"Vod":{}}');
      this.uploadDisabled = false;
      this.authUpload();
    },
    authUpload() {
      if (this.uploader !== null) {
        this.uploader.startUpload();
        this.uploadDisabled = true;
      }
    },
    handleRemove() {
      this.fileList = [];
      this.dialogImageUrl = '';
      this.$emit('dialogImage', '', this.imageSub);
    },
    handleRemoveAll(index, fileList) {
      const { url, name } = fileList[index];
      fileList.splice(index, 1);
      this.$emit('dialogImage', this.fileList, name ? '' : url);
    },
    handleChange(file, fileList) {
      if (this.limit > 1) {
        this.fileList = fileList;
        this.$emit('dialogImage', fileList);
      } else {
        this.isImg(file.raw);
        this.uploadAnLiYunImg(file.raw);
      }
    },
    handlePictureCardPreview(file) {
      this.isImg(file.raw);
    },
    isImg(file) {
      const url = URL.createObjectURL(file);
      this.dialogImageUrl = url;
    },
    getFileList() {
      return this.fileList;
    },
    isDialogVisible(url) {
      if (typeof url == 'string') {
        this.dialogListImageUrl = url;
      }
      this.dialogVisible = true;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    }
  },
  created() {
    if (this.filesList && this.filesList.length) this.fileList = this.filesList;
  },
  computed: {
    createObjectURL() {
      return raw => {
        return URL.createObjectURL(raw);
      };
    }
  },
  watch: {
    dialogImage(newVal) {
      this.dialogImageUrl = newVal;
    }
  }
};
</script>
<style lang="scss">
.upload2 {
  .uploadImg {
    position: relative;
    border: 2px dashed #999999;
    box-sizing: border-box;
    margin-right: 20px;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .icon {
      width: 100%;
      height: 100%;
      position: absolute;
      display: none;
      background-color: rgba(0, 0, 0, 0.5);
    }
    span {
      font-size: 30px;
      color: #ffffff;
      cursor: pointer;
    }
    .zoom {
      margin-right: 30px;
    }
  }
  .uploadImg:hover .icon {
    display: flex;
  }
  .el-upload--picture-card {
    width: 205px;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: inherit;
    border: 2px dashed #999999;
  }
  .progresss {
    position: absolute;
    width: 100%;
    height: 100%;
    .progress_bj {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
    .el-progress--circle {
      margin-left: 40px;
      .el-progress-circle {
        width: 120px !important;
        height: 120px !important;
      }
    }
    .el-progress__text {
      color: #e5e9f2;
    }
  }
}
.imgDialog {
  .el-dialog {
    overflow: auto;
    border-radius: 0;
  }
  .el-dialog__body {
    background-color: #ffffff;
  }
}
</style>
<style lang="scss" scoped>
.upload2 {
  & > div {
    display: flex;
    flex-wrap: wrap;
  }
  .el-upload {
    .plus_tips {
      .icon_plus {
        width: 30px;
        height: 30px;
        margin: auto;
        border-radius: 50%;
        border: 2px solid #999999;
        span {
          display: block;
          font-size: 34px;
          margin-top: -7px;
        }
      }
      span {
        font-size: 16px;
        color: #999999;
      }
    }
  }
}
</style>

<template>
  <div class="postVideo">
    <VideoTitle title="发布视频"></VideoTitle>
    <div class="p40">
      <div class="postVideo_top fsb">
        <div class="postVideo_top_w">
          <span class="text">{{ queryId == 2 ? '知识大讲堂' : '青年演说家' }}</span>
          <video controls :src="completeAndFreevideoUrl">
            抱歉，您的浏览器不支持内嵌视频，不过不用担心，你可以
            <a :href="completeAndFreevideoUrl">下载</a>
            并用你喜欢的播放器观看!
          </video>
          <div v-if="isOperation" class="fsb tips replace">
            <div @click="videoDivision('更换视频', 2)">
              <img src="../../assets/img/creatorPlatform/replace.png" />
              <span>更换视频</span>
            </div>
          </div>
        </div>
        <div v-if="queryId == 2" class="postVideo_top_w">
          <span class="text">高光时刻</span>
          <video v-if="highlightvideoUrl" controls :src="highlightvideoUrl">
            抱歉，您的浏览器不支持内嵌视频，不过不用担心，你可以
            <a :href="highlightvideoUrl">下载</a>
            高光简介规则
          </video>
          <div v-else v-loading="loadingHighlight" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
            <el-empty class="empty" :description="descriptionText"></el-empty>
          </div>
          <div v-if="isOperation" class="tips" :class="{ disabledHighlight: !objVideoData }">
            <div @click="editVideo" class="left">
              <img src="../../assets/img/creatorPlatform/cutting.png" />
              <span>截取高光</span>
            </div>
            <div @click="videoDivision('上传高光', 1)" class="right">
              <img src="../../assets/img/creatorPlatform/replace.png" />
              <span>上传高光</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 封面 -->
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <div class="cover" :class="{ operation: !isOperation }">
          <div class="cover_left">
            <span class="text">{{ queryId == 1 ? '青年演说家' : '知识大讲堂' }}视频封面</span>
            <UploadImg @dialogImage="dialogImage" :imageSub="propImg" :dialogImage="ruleForm[propImg]" :isOperation="isOperation" :queryId="String(queryId)" :videoId="videoInitId"></UploadImg>
          </div>
          <div v-show="queryId == 2" class="cover_right">
            <span class="text">高光时刻封面</span>
            <UploadImg @dialogImage="dialogImage" imageSub="videoEditingImg" :dialogImage="ruleForm.videoEditingImg" :queryId="String(queryId)" :isOperation="isOperation" highligh="1" :videoId="videoInitId"></UploadImg>
          </div>
        </div>

        <!-- 视频标题 -->
        <el-form-item class="video_title" prop="videoTitle">
          <span class="text">视频标题</span>
          <el-input v-model="ruleForm.videoTitle" placeholder="视频标题" maxlength="20" show-word-limit></el-input>
        </el-form-item>
        <!-- 选择视频分类 -->
        <el-form-item class="video_title" prop="videoCategory">
          <span class="text">选择分类</span>
          <div class="fsb">
            <el-select v-model="ruleForm.categoryId" placeholder="请选择">
              <el-option v-for="item in categoryOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
        </el-form-item>
        <!-- 视频简介 -->
        <el-form-item class="video_title" prop="videoDescribe">
          <span class="text">视频简介</span>
          <el-input v-model="ruleForm.videoDescribe" type="textarea" :rows="5" placeholder="填写你的视频简介" maxlength="120" show-word-limit></el-input>
        </el-form-item>
        <!-- 添加标签 -->
        <el-form-item class="video_title" prop="videoLabelOne">
          <span class="text">添加标签</span>
          <div class="fsb">
            <el-input v-model="ruleForm.videoLabelOne" placeholder="你可以输入标签，添加更多标签请点击右侧添加标签"></el-input>
            <el-button class="el_btn" @click="addLaber">添加标签</el-button>
          </div>
          <!-- 最多2个 -->
          <div class="recommend_label">
            <span>{{ recommendData.length ? '已选' : '推荐' }}标签</span>
            <div class="elTag flex">
              <el-tag v-for="tag in recommendData" :key="tag" closable :disable-transitions="false" @close="handleClose(tag)">{{ tag }}</el-tag>
            </div>
          </div>
        </el-form-item>
        <!-- 学校 -->
        <el-form-item v-show="queryId == 1" class="video_title" prop="videoTitle">
          <span class="text">院校</span>
          <el-input v-model="ruleForm.school" placeholder="院校" maxlength="20" show-word-limit></el-input>
        </el-form-item>
        <!-- 选择合集 -->
        <el-form-item class="video_title" prop="videoCompilationsId">
          <span class="text">选择合集</span>
          <div class="fsb">
            <el-select v-model="ruleForm.videoCompilationsId" placeholder="请选择">
              <el-option v-for="item in compilationsOptions" :key="item.compilationsId" :label="item.compilationsTitle" :value="item.compilationsId"></el-option>
            </el-select>
            <el-button class="el_btn" @click="newCollection">新建合集</el-button>
          </div>
        </el-form-item>
        <!-- 选择价格 -->
        <el-form-item v-if="queryId == 2" class="video_title" prop="prices">
          <div class="fac pb">
            <span class="text">选择价格</span>
            <img class="icon_img" src="../../assets/img/creatorPlatform/way.png" /><span style="font-size:12px; color=''">1元=10麒麟币</span>
          </div>
          <el-select v-model="ruleForm.prices" placeholder="请选择">
            <el-option v-for="(item, index) in pricesArray" :key="index" :label="item.name" :value="item.val"></el-option>
          </el-select>
        </el-form-item>
        <!-- 视频类型 -->
        <div class="video_title">
          <span class="text">视频类型</span>
          <div class="fac tips">
            <div class="tip fac pm" :class="{ active: !activeIndex }" @click="tipClick(0)">
              <div class="img"></div>
              <span>独家视频</span>
              <img class="icon_img" src="../../assets/img/creatorPlatform/way.png" />
            </div>
            <div class="tip fac" :class="{ active: activeIndex }" @click="tipClick(1)">
              <div class="img"></div>
              <span>非独家视频</span>
              <img class="icon_img" src="../../assets/img/creatorPlatform/way.png" />
            </div>
          </div>
        </div>
      </el-form>
      <el-button class="post_video" @click="postVideo" :disabled="!highlightvideoUrl && queryId == 2">发布视频</el-button>
    </div>
    <Dialog v-if="videoDialogObj.dialogVisible" @dialogClose="dialogClose" :videoDialogObj="videoDialogObj">
      <div class="dialog_body">
        <div v-if="videoDialogObj.newCollection" class="collectionName">
          <span style="float: left;">合集名称</span>
          <el-input v-model="collectionName" placeholder="请输入合集名称"></el-input>
        </div>
        <div v-else class="dialogUpload">
          <UploadVideo ref="uploadVideos" @duration="durationEidt" @closeEidt="closeEidt" :queryId="queryId" :videoId="videoId" :videoInitId="videoInitId"></UploadVideo>
        </div>
        <div v-if="videoDialogObj.newCollection" class="cover_left">
          <span class="text">上传讲义</span>
          <el-input v-model="file" placeholder="请输入合集名称" id="file" name="file" type="file"></el-input>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import VideoTitle from '@/components/public/VideoTitle.vue';
import UploadImg from '@/components/public/UploadImg.vue';
import { compilations, saveVideoByWeb, insertCompilation, videoSelectOne, videoUpdate, saveCover, category } from '@/utils/serveApi';
import UploadVideo from '@/components/public/UploadVideo.vue';
import Dialog from '@/components/public/Dialog.vue';
import { handleImage } from '@/utils/public';
import { newWebsocket, websocketClose } from '@/utils/socket.js';
export default {
  name: 'postVideo',
  data() {
    let videoLabelOne = (rule, value, callback) => {
      if (!value && !this.recommendData.length) {
        return callback(new Error('请输入视频标签'));
      } else {
        callback();
      }
    };
    return {
      objVideo: {},
      descriptionText: '暂无数据',
      objVideoData: null,
      files: [],
      ruleForm: {
        area: '', //地址
        isExclusive: 0, //是否是独家:0独家,1非独家
        prices: 10, //价格 10-100
        type: 1, //传1,固定 0是小知识视频,1正常视频
        videoAlId: '', //不填
        videoId: '',
        videoCompilationsId: '', //这个是选择的视频合集id
        videoDescribe: '', //视频描述
        videoEditingId: '', //不填
        videoEditingImg: '', //填写,高光视频的 封面地址
        videoImg: '', //填写,完整版视频的 封面地址
        videoLabelOne: '', //有就传, 视频标签一
        videoLabelTwo: '', //有就传,视频标签二
        videoTitle: '', //必传,视频标题
        categoryId: '',//视频分类
        school: ''//院校
      },
      activeIndex: 0,
      videoDialogObj: {
        dialogVisible: false,
        title: '裁取高光'
      },
      pricesArray: [{ "val": 10, "name": 10 }, { "val": 20, "name": 20 }, { "val": 30, "name": 30 }, { "val": 40, "name": 40 }, { "val": 50, "name": 50 }, { "val": 60, "name": 60 }, { "val": 70, "name": 70 }, { "val": 80, "name": 80 }, { "val": 90, "name": 90 }, { "val": 100, "name": 100 }],
      recommendData: [],
      compilationsOptions: [],
      categoryOptions: [],
      pricesOptions: [],
      isOperation: true,
      queryId: '',
      queryVideoId: '',
      videoId: 0,
      videoInitId: '',
      collectionName: '',
      file: '',
      completeAndFreevideoUrl: '',
      highlightvideoUrl: '',
      loadingHighlight: true,
      rules: {
        videoTitle: [{ required: true, message: '请输入视频标题', trigger: 'blur' }],
        videoDescribe: [{ required: true, message: '请输入视频简介', trigger: 'blur' }],
        videoLabelOne: [{ validator: videoLabelOne, trigger: 'blur' }],
        videoCompilationsId: [{ required: true, message: '请选择合集', trigger: 'change' }],
        prices: [{ required: true, message: '请选择价格', trigger: 'change' }],
        isExclusive: [{ required: true, message: '请选择类型', trigger: 'change' }]
      },
      durationData: {},
      formData: {},
      videoState: 1,
      isPropImgUpload: false
    };
  },
  methods: {
    webSocket() {
      newWebsocket(this.$store.getters.userData.userId, event => {
        const res = event.data;
        if (res != '连接成功') {
          const obj = JSON.parse(res);
          if (obj && !obj.message) return;
          const message = JSON.parse(obj.message);
          if (message.msgType == 0) {
            this.clipParameters(message.data);
          } else {
            if (message?.msgType == 1) {
              const { data } = message;
              this.highlightvideoUrl = data;
              handleImage(data, imageUploadURL => (this.ruleForm.videoEditingImg = imageUploadURL));
            }
          } //990不做处理
        }
      });
    },
    clipParameters(data) {
      if (!data) return;
      this.loadingHighlight = false;
      const msgObj = JSON.parse(data);
      this.objVideoData = true;
      const { MediaId, FileInfoList, MediaBasicInfo } = msgObj.MediaInfo;
      const { FileUrl, FileName, Duration } = FileInfoList[0].FileBasicInfo;
      const { CoverURL, SpriteImages, Snapshots } = MediaBasicInfo;
      const spriteArr = JSON.parse(SpriteImages);
      const sprite = spriteArr[0];
      const config = JSON.parse(sprite.Config);
      const objData = {
        mediaType: 'video',
        mediaId: MediaId,
        url: FileUrl,
        video: {
          title: FileName,
          coverUrl: CoverURL,
          duration: Number(Duration),
          snapshots: Snapshots,
          sprites: sprite.SnapshotUrlList,
          spriteConfig: {
            num: config.Num,
            lines: config.SpriteSnapshotConfig?.Lines,
            cols: config.SpriteSnapshotConfig?.Columns
          }
        }
      };
      this.objVideo = objData;
    },
    tipClick(id) {
      if (!this.isOperation) return;
      this.activeIndex = id;
    },
    postVideo() {
      if (this.isPropImgUpload) {
        const message = this.queryId == 1 ? '青年演说家' : '知识大讲堂';
        this.$message.warning(message + '封面必填');
        return;
      }
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          websocketClose();
          const ruleForm = this.ruleForm;
          const queryVideoId = this.queryVideoId;
          const objUpdate = { ...ruleForm };
          objUpdate.type = this.queryId == 1 ? 0 : 1;
          const recommendData = this.recommendData;
          if (!recommendData.length) {
            this.$message('请添加视频标签');
            return;
          }
          objUpdate.videoLabelOne = recommendData[0];
          objUpdate.videoLabelTwo = recommendData[1] || '';
          objUpdate.isExclusive = this.activeIndex;
          if (queryVideoId) {
            objUpdate.videoId = queryVideoId;
            videoUpdate(objUpdate).then(res => {
              if (res && res.success) this.$router.push('/adminVideo');
            });
          } else {
            objUpdate.videoImg = '';
            objUpdate.videoEditingImg = '';
            this.saveVideoByWeb(objUpdate);
          }
        } else {
          return false;
        }
      });
    },
    saveVideoByWeb(objUpdate) {
      saveVideoByWeb(objUpdate).then(res => {
        if (res.success) {
          this.$confirm('请耐心等待审核', '视频发布成功', {
            confirmButtonText: '确定',
            showClose: false,
            showCancelButton: false,
            type: 'success',
            customClass: 'postVideoSuccess',
            closeOnClickModal: false,
            center: true
          }).then(() => {
            this.$router.push('/adminVideo');
          });
        } else {
          this.$message.error(res.stateDesc);
        }
      });
    },
    dialogImage(url, sub) {
      if (sub == 1) {
        this.isPropImgUpload = false;
      } else if ((this.queryId == 1 && sub == 'videoEditingImg') || (this.queryId == 2 && sub == 'videoImg')) {
        this.isPropImgUpload = true;
      }
      if (!url) {
        this.ruleForm[sub] = '';
        return;
      }
    },
    saveCover(formData, sub) {
      saveCover(formData).then(res => {
        const data = res.data;
        if (data) this.ruleForm[sub] = data;
      });
    },
    formatTooltip(val) {
      if (val) return this.handleSliderValue(val);
    },
    handleSliderValue(val) {
      return val
        .toFixed(2)
        .replace('.', ':')
        .padStart(5, 0);
    },
    videoDivision(name, index) {
      this.videoDialogObj.title = name;
      this.videoDialogObj.dialogVisible = true;
      this.videoDialogObj.newCollection = false;
      this.videoId = index;
    },
    editVideo() {
      this.loadingHighlight = true;
      this.descriptionText = '视频正在处理中...';
      const link = this.$router.resolve({
        name: 'editVideo',
        query: {
          id: JSON.stringify(this.objVideo)
        }
      });
      window.open(link.href, '_blank');
      if (this.videoState == 99) {
        this.webSocket();
        this.videoState = 1;
      }
    },
    durationEidt(obj) {
      this.durationData = obj;
    },
    closeEidt({ url }) {
      if (url) {
        if (this.queryId == 2 && this.videoId == 1) {
          this.highlightvideoUrl = url;
        } else {
          this.completeAndFreevideoUrl = url;
        }
      }
      this.videoDialogObj.dialogVisible = false;
    },
    dialogClose(determine) {
      if (determine == 'determine') {
        if (this.videoDialogObj.newCollection) {
          let formData = new FormData();
          //合集
          if (this.collectionName) {
            formData.append('compilationsTitle', this.collectionName);
          } else {
            this.$message('合集名称不能为空');
          }
          let fileObj2 = document.getElementById("file").files[0];
          formData.append('file', fileObj2);
          insertCompilation(formData).then(() => {
            this.getCompilation();
            this.videoDialogObj.dialogVisible = false;
            this.$message.success('新建合集成功');
          });
        } else {
          //视频
          const { url, imageUploadURL, isIndex } = this.durationData;
          if (url) {
            if (this.queryId == 1) {
              this.completeAndFreevideoUrl = url;
              const propImg = this.propImg;
              this.ruleForm[propImg] = imageUploadURL;
            } else {
              if (isIndex == 1) {
                this.highlightvideoUrl = url;
                this.ruleForm.videoEditingImg = imageUploadURL;
              } else {
                this.completeAndFreevideoUrl = url;
                this.ruleForm.videoImg = imageUploadURL;
                //重置高光数据
                this.objVideoData = null;
                this.ruleForm.videoEditingImg = '';
                this.highlightvideoUrl = '';
                this.loadingHighlight = true;
                this.descriptionText = '视频正在处理中...';
              }
              if (this.videoState == 99) {
                this.webSocket();
                this.videoState = 1;
              }
            }
          }
          this.$refs.uploadVideos.startUpload();
        }
      } else {
        this.videoDialogObj.dialogVisible = false;
      }
    },
    newCollection() {
      this.collectionName = '';
      this.file = '';
      this.videoDialogObj.title = '新建合集';
      this.videoDialogObj.compilationsId = '';
      this.videoDialogObj.newCollection = true;
      this.videoDialogObj.dialogVisible = true;
    },
    addLaber() {
      const { videoLabelOne } = this.ruleForm;
      if (videoLabelOne) {
        const recommendData = this.recommendData;
        if (recommendData.length > 1) {
          this.$message('标签最多添加两个');
          return;
        }
        this.recommendData.push(videoLabelOne);
        this.ruleForm.videoLabelOne = '';
      } else {
        this.$message('请先输入标签名称');
      }
    },
    getCompilation(n) {
      //下拉框
      const { userData } = this.$store.getters;
      compilations(userData.userId).then(res => {
        const { data } = res;
        this.compilationsOptions = data;
        if (!n) this.ruleForm.videoCompilationsId = data[0].compilationsId;
      });
    },
    getCategory(n) {
      //下拉框
      const { userData } = this.$store.getters;
      category(userData.userId).then(res => {
        const { data } = res;
        let newData = [];
        data.forEach(item => {
          if (item.id != 1) {
            newData.push(item);
          }
        })
        this.categoryOptions = newData;
        if (!n) this.ruleForm.categoryId = data[0].id;
      });
    },
    handleClose(tag) {
      this.recommendData.splice(this.recommendData.indexOf(tag), 1);
    }
  },
  computed: {
    propImg() {
      return this.queryId == 1 ? 'videoEditingImg' : 'videoImg';
    }
  },
  components: { VideoTitle, UploadImg, Dialog, UploadVideo },
  created() {
    const { videoId, queryId, fileRaw, videoInitId, editVideo } = this.$route.query;
    if (videoId) {
      videoSelectOne(videoId).then(res => {
        const { videoTitle, videoPlayUrl, videoEditingPlayUrl, videoDescribe, type, prices, isExclusive, videoCompilationsId, videoImg, videoEditingImg, videoState, labelOne, labelTwo } = res.data.video;
        //type 0是小知识视频,1正常视频  queryId 1  小知识视频 2  完整版视频
        this.queryId = type ? 2 : 1;
        this.completeAndFreevideoUrl = videoPlayUrl;
        this.ruleForm.videoTitle = videoTitle;
        this.ruleForm.videoDescribe = videoDescribe;
        this.ruleForm.isExclusive = isExclusive || 0;
        this.activeIndex = isExclusive || 0;
        this.ruleForm.videoCompilationsId = videoCompilationsId;
        this.ruleForm.videoEditingImg = videoEditingImg || '1';
        this.videoInitId = videoId;
        this.ruleForm.videoId = videoId;
        this.queryVideoId = videoId;
        this.highlightvideoUrl = videoEditingPlayUrl;
        //完整版的封面处理
        videoImg ? (this.ruleForm.videoImg = videoImg) : handleImage(videoPlayUrl, imageUploadURL => (this.ruleForm.videoImg = imageUploadURL));
        if (labelOne) this.recommendData.push(labelOne);
        if (labelTwo) this.recommendData.push(labelTwo);
        if (videoState == 99 || editVideo) {
          if (type) {
            this.ruleForm.prices = prices || 10;
            this.videoState = videoState;
            // if (!videoEditingImg && videoEditingPlayUrl) handleImage(videoEditingPlayUrl, imageUploadURL => (this.ruleForm.videoEditingImg = imageUploadURL));
            this.clipParameters(res.data.edit);
            if (this.queryId == 2 && !res.data.edit) this.webSocket();
          } else {
            this.ruleForm.prices = 0;
          }
        } else {
          this.isOperation = false;
        }
      });
    } else {
      this.queryId = queryId;
      this.completeAndFreevideoUrl = fileRaw;
      handleImage(fileRaw, imageUploadURL => {
        const propImg = this.propImg;
        this.ruleForm[propImg] = imageUploadURL;
      });
      this.videoInitId = videoInitId;
      this.ruleForm.videoId = videoInitId;
      if (queryId == 1) this.ruleForm.prices = 0;
      if (queryId == 2) this.webSocket();
    }
    this.getCompilation(1); //选择合集下拉框
    this.getCategory(1);//选择分类下拉框
  },
  beforeDestroy() {
    websocketClose();
  }
};
</script>

<style lang="scss">
//提示框
.postVideoSuccess {
  .el-message-box__title {
    justify-content: flex-start;
  }
  .el-message-box__message {
    text-align: left;
  }
  .el-message-box__btns {
    text-align: right;
  }
  .el-message-box__status::before {
    font-size: 24px;
  }
  .el-message-box__title {
    font-size: fz16;
  }
  .el-message-box__message p {
    //font-size: $r;
    padding-left: 24px;
  }
  .el-button--small {
    font-size: 12px;
  }
}
.postVideo {
  .dialog_body {
    margin: 40px;
    text-align: left;
    & > img {
      width: 238px;
      margin-bottom: 50px;
    }
    .el-slider {
      flex: 1;
      margin: 0;
      .el-slider__runway {
        height: 12px;
        background: #999999;
        border-radius: 0;
        .el-slider__bar {
          height: 12px;
          background: #ffb600;
        }
        .el-slider__button-wrapper {
          top: -29px;
          .el-slider__button {
            width: 26px;
            height: 48px;
            border-radius: 0;
            border: 0;
          }
        }
        & > div:nth-child(2) {
          .el-slider__button {
            background: url("../../assets/img/creatorPlatform/cutting-left.png")
              no-repeat;
            background-size: cover;
          }
        }
        & > div:nth-child(3) {
          .el-slider__button {
            background: url("../../assets/img/creatorPlatform/cutting-right.png")
              no-repeat;
            background-size: cover;
          }
        }
        .el-slider__stop {
          width: 8px;
          height: 8px;
          margin-top: 2px;
        }
        .el-slider__marks {
          .el-slider__marks-text {
            box-sizing: border-box;
            background: #eef2f5;
            border-radius: 10px;
            font-size: 20px;
            color: #999999;
            white-space: nowrap;
            padding: 14px 24px;
            margin-top: 35px;
          }
        }
      }
    }
  }
  .el-dialog__footer {
    margin: 0 40px;
  }
  .upload2 {
    .uploadImg {
      width: 205px;
      height: 125px;
    }
  }
  .postVideo_top_w {
    .text {
      margin-bottom: 10px;
      display: block;
    }
    .el-loading-mask {
      background-color: rgba(0, 0, 0, 0.8);
      border-radius: 20px;
    }
  }
  .p40 {
    .post_video.is-disabled {
      background: #dcdfe6;
    }
  }
  .el-form-item__content {
    font-size: 20px;
  }
  .el-image {
    .image-slot {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: #f5f7fa;
      color: #909399;
      font-size: 30px;
    }
  }
}
</style>
<style lang="scss" scoped>
.postVideo {
  font-size: 20px;
  .pb {
    padding-bottom: 10px;
    & > img {
      padding-left: 10px;
    }
  }
  &_top {
    .text {
      padding-top: 14px;
    }
    &_w {
      width: 49%;
      margin-top: 16px;
      .empty {
        width: 100%;
        height: 240px;
        border: 1px solid #ebebeb;
        border-radius: 20px;
      }
    }
    video {
      width: 100%;
      height: 240px;
      object-fit: contain;
      border-radius: 20px;
      background-color: #000;
    }
    .tips {
      padding: 20px 40px 34px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      & > div {
        cursor: pointer;
        img {
          width: 34px;
        }
        span {
          display: block;
          color: #999999;
        }
      }
    }
  }
  .cover {
    position: relative;
    margin-bottom: 34px;
    &_left {
      margin-right: 40px;
      margin-bottom: 20px;
    }
    &_right {
      .cover_img {
        width: 569px;
        height: 125px;
        border-radius: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .upload2 {
      margin-top: 4px;
    }
  }
  .operation {
    display: flex;
    margin-top: 10px;
    margin-bottom: 0;
    .cover_left {
      width: 49%;
    }
  }
  .recommend_label {
    & > span {
      font-size: 16px;
      color: #999999;
      display: block;
      padding: 10px 0 12px;
    }
    .elTag {
      margin-top: -10px;
      span {
        color: #2985ff;
        background: #eef2f5;
        margin-right: 10px;
      }
    }
  }
  .tips {
    .tip {
      font-size: 24px;
      color: #999999;
      cursor: pointer;
      margin-top: 10px;
      .img {
        width: 24px;
        height: 24px;
        padding-right: 12px;
        background: url("../../assets/img/creatorPlatform/icon4.png") no-repeat;
        background-size: contain;
      }
      span {
        padding: 0 10px;
      }
    }
    .pm {
      margin-right: 80px;
    }

    .tip.active {
      color: #ffb600;
      .img {
        background: url("../../assets/img/creatorPlatform/icon3.png") no-repeat;
        background-size: contain;
      }
    }
    .left {
      float: left;
    }
    .right {
      float: right;
    }
  }
  .disabledHighlight {
    cursor: no-drop;
    .left {
      display: none;
    }
    .right {
      float: inherit;
    }
    span {
      color: #dcdfe6;
    }
  }
  .replace > div {
    margin: auto;
  }
  .video_title {
    margin-bottom: 30px;
    .icon_img {
      min-width: 20px;
      height: 20px;
    }
  }
  .videoImg .el-input {
    display: none;
  }
  .el_btn {
    margin-left: 24px;
    height: 44px;
    background: #ffb600;
    color: #ffffff;
    font-size: 16px;
    border: 0;
    border-radius: 8px;
  }
  .post_video {
    width: 400px;
    height: 58px;
    background: #ffb600;
    border-radius: 8px;
    margin: 0 auto 66px;
    color: #ffffff;
    font-size: 22px;
    display: block;
    border: 0;
  }
}
</style>

<template>
  <el-dialog :width="videoDialogObj.wdith ? videoDialogObj.wdith : '50%'" :title="videoDialogObj.title" :visible.sync="videoDialogObj.dialogVisible" :close-on-click-modal="false" :show-close="false" top="5vh">
    <slot></slot>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button class="determine" @click="handleClose('determine')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'dialogs',
  props: {
    videoDialogObj: Object
  },
  methods: {
    handleClose(str = '') {
      this.$emit('dialogClose', str);
    }
  }
};
</script>

<style lang="scss">
.el-dialog {
  border-radius: 24px;
  .el-dialog__header {
    padding: 30px 40px 0;
    .el-dialog__title {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
    }
  }
  .el-dialog__body {
    padding: 0px 40px;
    font-size: 22px;
    color: #000000;
  }
  .el-dialog__footer {
    padding: 0px 40px 40px;
    .dialog-footer {
      display: flex;
      justify-content: space-between;
      .el-button {
        width: 240px;
        height: 54px;
        background: #c1c1c1;
        border-radius: 10px;
        font-size: 22px;
        color: #ffffff;
      }
      .determine {
        background: #ffb600;
      }
    }
  }
}
</style>

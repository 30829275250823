<template>
  <div class="uploadVideo1">
    <el-upload class="upload-demo" drag :on-change="handleChange" action="#" :auto-upload="false" :show-file-list="false" :on-remove="handleRemove" accept=".mp4, .qlv, .qsv, .ogg, .ogv, .flv, .avi, .wmv, .rmvb" :disabled="!videoFlag">
      <div v-if="videoFlag" class="plus_tips">
        <img src="../../assets/img/creatorPlatform/upload.png" />
        <div class="el-upload__text"><span>上传视频</span>或者拖拽到此处</div>
      </div>
      <video class="video" v-else-if="showVideoPath" :src="showVideoPath" controls="controls">您的浏览器不支持视频播放</video>
      <el-progress v-else type="circle" :percentage="videoUploadPercent"></el-progress>
    </el-upload>
  </div>
</template>

<script>
import { getEditingVideoVoucher, getFullVideoVoucher } from '@/utils/serveApi';
import { handleImage, localStorages } from '@/utils/public';
export default {
  name: 'uploadVideo',
  props: ['queryId', 'videoId', 'videoInitId'],
  data() {
    return {
      videoFlag: true,
      videoUploadPercent: 0,
      uploader: null,
      videoFile: null,
      getVideoVoucherData: {},
      showVideoPath: '',
      dialogVisible: false
    };
  },
  created() {
    this.initUploader();
  },
  methods: {
    initUploader() {
      this.uploader = new AliyunUpload.Vod({
        userId: this.$store.state.userData.userId,
        region: '',
        partSize: 1048576,
        parallel: 5,
        retryCount: 3,
        retryDuration: 2,
        onUploadstarted: async uploadInfo => {
          const getFullVideo = this.getVideoVoucherData.queryId == 1 ? getEditingVideoVoucher : getFullVideoVoucher;
          const videoQuery = this.videoInitId ? this.videoInitId : '';
          const { data } = await getFullVideo(videoQuery);
          const { videoUploadAuth, videoUploadAddress, videoId, videoUploadId } = data;
          this.getVideoVoucherData.videoId = videoId;
          this.$store.commit('getVoucherData', data);
          this.uploader.setUploadAuthAndAddress(uploadInfo, videoUploadAuth, videoUploadAddress, videoUploadId);
        },
        onUploadSucceed: uploadInfo => {
          this.videoFlag = true;
          const { file } = uploadInfo.checkpoint;
          const { url, videoId } = this.getVideoVoucherData;
          this.$store.commit('videoFile', file);
          this.dialogVisible ? this.$emit('closeEidt', { url }) : this.$emit('duration', { url, videoId });
          this.dialogVisible = false;
        },
        onUploadFailed: (uploadInfo, code, message) => console.log('文件上传失败------------', message),
        onUploadProgress: (uploadInfo, totalSize, loadedPercent) => (this.videoUploadPercent = Math.ceil(loadedPercent * 100)),
        onUploadTokenExpired: uploadInfo => console.log('上传凭证或STS token超时------------', uploadInfo),
        onUploadEnd: () => console.log('上传成功------------')
      });
    },
    beforeUploadVideo(file) {
      const isLt10M = file.size / 1024 / 1024 < 2000;
      if (!isLt10M) {
        this.$message.error('上传视频大小不能超过2000MB哦!');
        return false;
      }
    },
    handleChange(file) {
      const files = file.raw;
      const url = URL.createObjectURL(files);
      const audioElement = new Audio(url);
      const { smallVIdeoMin, smallVIdeoMax, fullVideoMin, fullVideoMax, cropHighVideoMin, cropHighVideoMax } = localStorages('getDataConfig');
      audioElement.addEventListener('loadedmetadata', () => {
        const duration = Math.round(audioElement.duration * 100) / 100;
        //duration是秒 8-60分钟是完整版视频 也就是480-3600秒 full  小知识和高光都是30-到60秒 small
        if (duration == 0) {
          this.$message('请先上传视频');
          return;
        }
        const isDuration = this.queryId == 2 && this.videoInitId ? duration >= cropHighVideoMin && duration <= cropHighVideoMax : duration >= smallVIdeoMin && duration <= smallVIdeoMax;
        if (isDuration || (duration >= fullVideoMin && duration <= fullVideoMax)) {
          const isIndex = duration > 60 ? 2 : 1;
          const queryId = this.queryId;
          const videoId = this.videoId;
          if (queryId == 1 && isIndex == 2) {
            this.$message('只能上传青年演说家');
            return;
          } else if (queryId == 2) {
            if (videoId && isIndex != videoId) {
              const str = videoId == 1 ? '高光时刻' : '完整版视频';
              this.$message('只能上传' + str);
              return;
            } else if (isIndex == 1 && !videoId) {
              this.$message('只能上传完整版视频');
              return;
            }
          }
          if (queryId == 0) this.$emit('duration', { isIndex });
          this.getVideoVoucherData.queryId = isIndex;
          this.getVideoVoucherData.url = url;
          this.videoFlag = false;
          if (videoId) {
            this.showVideoPath = url;
            this.videoFile = files;
            handleImage(url, imageUploadURL => this.$emit('duration', { url, imageUploadURL, isIndex }));
          } else {
            this.startUpload(files);
          }
        } else {
          this.$message('视频超出限制');
        }
      });
    },
    startUpload(files = null) {
      if (this.uploader) this.videoUploadPercent = 0;
      if (!files) {
        this.showVideoPath = '';
        this.dialogVisible = true;
      }
      const file = files ? files : this.videoFile;
      this.uploader.addFile(file, null, null, null, '{"Vod":{}}');
      this.uploader.startUpload();
    },
    handleRemove() {
      this.videoFlag = true;
      this.$emit('duration', 0);
    }
  }
};
</script>
<style lang="scss">
.uploadVideo1 {
  .el-upload {
    width: 100%;
    .el-upload-dragger {
      width: 100%;
      height: 368px;
      border-radius: 14px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      border: 0;
      //@include flexJustify;
    }
  }
}
.uploadVideo2 {
  .el-upload-dragger {
    width: 205px;
    height: 125px;
    line-height: inherit;
    border: 2px dashed #999999;
  }
  .el-progress-circle {
    width: 125px !important;
    height: 125px !important;
  }
}
</style>
<style lang="scss" scoped>
.uploadVideo1 {
  position: relative;
  .el-upload {
    .el-upload__text {
      font-size: 20px;
      span {
        color: #ffb600;
      }
    }
  }
  .video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .iconClose {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    .el-icon-close {
      color: #ffffff;
      font-size: 20px;
    }
  }
}
.uploadVideo2 {
  position: relative;
  .el-upload {
    .plus_tips {
      .icon_plus {
        width: 30px;
        height: 30px;
        margin: 30px auto 10px;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid #999999;
        span {
          display: block;
          font-size: 34px;
          margin-top: -5px;
        }
      }
      span {
        font-size: 16px;
        color: #999999;
      }
    }
  }
}
</style>

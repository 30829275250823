let websocketConnectdCount = 0;
let websocket = null;
let userIds = null;
let heartCheck = {
  timeout: 10000,
  timeoutObj: null,
  serverTimeoutObj: null,
  reset: function () {
    clearTimeout(this.timeoutObj);
    clearTimeout(this.serverTimeoutObj);
    this.timeoutObj = null;
    this.serverTimeoutObj = null;
    return this;
  },
  start: function () {
    this.serverTimeoutObj = setTimeout(function () {
      if (websocket.readyState === 1) {
        websocket.send(JSON.stringify({ checkHeart: 'ping', toUserId: userIds }));
        heartCheck.reset().start();
      } else {
        newWebsocket();
      }
    }, this.timeout);
  }
};
const newWebsocket = (userId, cb) => {
  if (window.WebSocket) {
    if (!websocket) {
      const ws = 'wss://ws.baiwanqilin.com/websocket/owner/' + userId;
      websocket = new WebSocket(ws);
      userIds = userId;
    }
    websocket.onopen = function (e) {
      heartCheck.reset().start();
    };

    websocket.onerror = function () {
      websocketConnectdCount++;
      if (websocketConnectdCount <= 5) {
        newWebsocket();
      }
    };
    websocket.onmessage = function (event) {
      if (event && cb) cb(event);
      heartCheck.reset().start();
    };
  }
};

window.onbeforeunload = () => websocketClose();

const websocketClose = () => {
  heartCheck.reset();
  if (websocket) {
    websocket.close();
  }
  websocket = null;
};
export { newWebsocket, websocketClose };
